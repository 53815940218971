import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest, makeRequestWithErrorMessage } from '../../../core/services/v1/request';
import Config from '../../../core/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { dateFormat, formatDate } from '../../../core/helper/date-format';
import { toast } from '../../../core/lib/toastAlert';
import '../User/User.css';

const StakeDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)
    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(true)

    const getStakeDetails = async () => {
        setLoading(() => true)
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'admin/get-stake-all/' + id })
        if (res?.status) {
            setData(() => res.data[0])
            setUser(() => res.data[0].userData)
            setStatus(() => res.data[0]?.withdrawStatus)
        }
        setLoading(() => false)
    }
    React.useEffect(() => {
        getStakeDetails();
    }, [])
    const changeTheStatus = async () => {
        const data = {
            withdrawStatus: status,
            id: id,
        }
        const params = {
            url: `${Config.V1_API_URL}admin/update-stake-withdraw`,
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getStakeDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    return (
        <div className='container-fluid mb-3 mx-auto overflow-hidden'>

            <div className='mb-3'>
                <h4>Stake Details</h4>
            </div>

            {loading && <p>Loading...</p>}
            <div className='d-flex justify-content-between align-items-start w-100 flex-column flex-xl-row' style={{ gap: '5px' }}>
                <div className='w-100'>
                    <h5 className='fw-bold'>Stake Info:</h5>

                    <div className='flex-info-two'>
                        <div>
                            <p>Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.name}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Amount:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.amount}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Price:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.price}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Required:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.required}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>withdrawn Amount:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.withdrawn}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>stake Date:</p>
                        </div>
                        <div>
                            <p className='text-break'>{dateFormat(data?.stakeDate)}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>status:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.status == false ? 'Closed' : 'Open'}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>withdraw Req Date:</p>
                        </div>
                        <div>
                            <p className='text-break'>{dateFormat(data?.withdrawReqDate)}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>withdrawStatus:</p>
                        </div>
                        <div>
                            {data?.withdrawStatus == 0 ? 'Pending' : data?.withdrawStatus == 1 ? 'Success' : data?.withdrawStatus == 2 ? 'Rejected' : 'Not Withdrawn'}

                        </div>
                    </div>

                </div>
                <div className='w-100'>
                    <h5 className='fw-bold'>User Info:</h5>

                    <div className='flex-info-two'>
                        <div>
                            <p>First Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.firstName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Last Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.lastName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Email:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.email}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Wallet Address:</p>
                        </div>
                        <div>
                            <Form.Control size="sm" className='mb-1' type="text" placeholder="Small text" value={user?.walletAddress} readOnly />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Phone No:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.phoneNo}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.accountName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Number:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.accountNumber}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>IFSC Code:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.ifscCode}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Bank Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.bankName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakeDetails;