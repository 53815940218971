import { Button } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useNavigate } from 'react-router-dom';
// import { AdminContext } from '../../contexts/AdminContext';
import './login.css';
import { AdminContext } from '../../../core/context/AdminContext/AdminContext';

const Login = () => {
    const [visiblePassword, setVisiblePassword] = useState(false);
    const { admin, token, isAuthenticating, login, loading } = useContext(AdminContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticating) {
            navigate(`/otp`, { replace: true });
        }
        if (admin?._id) {
            navigate("/dashboard", { replace: true });
        }
    }, [admin, navigate, isAuthenticating, token]);

    const handleLogin = (e) => {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;
        // console.log(email);
        // console.log(password);
        login(email, password);
    };

    return (
        <div className='handleTheLoginBody'>
            <div className='container mx-auto'>
                <div className='pt-5 forCard  w-50 p-5 rounded mx-auto'>
                    <div className='mx-auto text-center'>
                        <img src="https://i.ibb.co/LQmQWLr/logo192.png" className='handleLogoLogin' alt="logo" />
                    </div>
                    <hr />
                    <div className='mt-4 pt-2'>
                        <form onSubmit={handleLogin}>
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1" className='bg-dark border-0 text-white'><i class="fas fa-envelope"></i></InputGroup.Text>
                                <Form.Control
                                    style={{ textTransform: "lowercase" }}
                                    className='inputBackground custom-white'
                                    placeholder="Email"
                                    aria-label="Username"
                                    type='email'
                                    required
                                    aria-describedby="basic-addon1"
                                    name="email"
                                />
                            </InputGroup>
                            <br />
                            {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
                            <InputGroup className="mb-3 mt-3">
                                <InputGroup.Text className='bg-dark border-0 text-white'><i class="fas fa-lock"></i></InputGroup.Text>
                                <Form.Control aria-label="Amount (to the nearest dollar)" className='inputBackground custom-white' placeholder='password' type={visiblePassword ? "text" : "password"} required name="password" />
                                <InputGroup.Text className='bg-dark text-center border-0 cursor-pointer text-white' role="button" type="button" onClick={() => setVisiblePassword(!visiblePassword)}>{
                                    visiblePassword ? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>
                                }</InputGroup.Text>
                            </InputGroup>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Link className='text-decoration-none forgetPass' to='/forget-password'><p>Forgot password?</p></Link>
                            </Form.Group>
                            <div className='mx-auto text-center'>
                                <Button style={{ backgroundColor: '#f74545' }} className='button-34 ps-5 pe-5 pt-2 pb-2' type="submit">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Login;