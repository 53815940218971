import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Col } from 'react-bootstrap';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useNavigate } from 'react-router-dom';
import './home.css';

const HomeCard = ({ data }) => {
    const { name = '', count = 0, link = '/', _id = 0 } = data
    const navigate = useNavigate()
    return (
        <Col xs={12} md={6} xl={4} xxl={3} key={_id}>
            <Card sx={{ minWidth: 275 }} className='mx-auto hoverGlow' onClick={() => navigate(link)}>
                <CardContent sx={{
                    '&.MuiCardContent-root': {
                        paddingBottom: '16px'
                    }
                }}>
                    <Box className='d-flex align-items-center justify-content-between h-100' sx={{ minHeight: 70, }}>
                        <div>
                            <Typography sx={{ fontSize: { xs: 15, md: 17 }, gap: 1, textTransform: 'capitalize' }} gutterBottom className='d-flex justify-content-start align-items-center'>
                                <span><AccountTreeIcon sx={{ fontSize: { xs: 16, md: 20 } }} /></span><span>{name}</span>
                            </Typography>
                            {/* <Typography sx={{ fontSize: 20, color: 'khaki' }} gutterBottom>
                                {count}
                            </Typography> */}
                        </div>
                        <div>
                            <Typography sx={{ fontSize: { xs: 14, md: 16 }, textAlign: 'end' }} gutterBottom>
                                Pending
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 14, md: 16 } }} gutterBottom>
                                {count}
                            </Typography>
                        </div>
                    </Box>
                </CardContent>
            </Card>
        </Col>
    );
};

export default HomeCard;