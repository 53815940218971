import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentIcon from '@mui/icons-material/Payment';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AdminContext } from '../../../core/context/AdminContext/AdminContext';
import { Avatar } from '@mui/material';

const drawerWidth = 225;

function Dashboard(props) {
  const { window } = props;
  const { logout} = React.useContext(AdminContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate()
  const pathname = useLocation().pathname;
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const lists = [
    { name: 'Dashboard', icon: <DashboardIcon />, path: "/dashboard/home", id: 1 },
    { name: 'Admin', icon: <AdminPanelSettingsIcon />, path: "admin", id: 2 },
    { name: 'User', icon: <SupervisorAccountIcon />, path: "user", id: 4 },
    { name: 'Transaction', icon: <ReceiptLongIcon />, path: "transaction", id: 7 },
    { name: 'Payout', icon: <PaymentIcon />, path: "payout", id: 3 },
    { name: 'InstaTrade', icon: <CurrencyExchangeIcon />, path: "instaTrade", id: 8 },
    { name: 'Stake', icon: <CurrencyExchangeIcon />, path: "stake", id: 9 },
    { name: 'Bank', icon: <AccountBalanceIcon />, path: "bank", id: 5 },
    { name: 'Logout', icon: <LogoutIcon />, path: "logout", id: 6, isLog: true },
  ]
  const drawer = (
    <div>
      <Toolbar sx={{ justifyContent: 'center' }}>
      <Avatar alt="logo" src="https://i.ibb.co/LQmQWLr/logo192.png" />
      </Toolbar>
      <Divider />
      <List>
        {lists.map((text) => {
          const { id = 1, icon = '', path = '', name = '', isLog = false } = text
          const isActive = pathname.includes(path)
          return (
            <ListItem key={id} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  if (isLog) {
                    handleLogout() 
                  }
                  else { navigate(path); localStorage.setItem('page', name) }
                }}
                onKeyDown={handleDrawerClose}
                sx={{
                  minHeight: 48,
                  justifyContent: 'initial',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                    color: isActive ? 'rgb(90, 69, 206)' : ''
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={name} sx={{ color: isActive ? 'rgb(90, 69, 206)' : '' }} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{bgcolor:'rgb(90, 69, 206)'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {localStorage.getItem('page') ? localStorage.getItem('page') : 'Dashboard'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            position:'relative',
            zIndex: 1,
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1,  p: { xs: 1, sm: 3 }, minHeight: '100vh',  width: `calc(100% - ${drawerWidth}px)` }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}


export default Dashboard;
