import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { toast } from '../../../core/lib/toastAlert';
import { getCookie } from '../../../core/helper/cookie';
import axios from 'axios';
import Config from '../../../core/config';

const AdminEdit = (props) => {
    const [editShow, setEditShow] = React.useState(false);
    const [previewImage, setPreviewImage] = useState();
    const { row = [], admin = {} } = props;
    useEffect(() => {
        if (row?.avatar) {
            setPreviewImage(() => row?.avatar?.url)
        }
    }, [row?.avatar])
    const selectFile = (event) => {
        // setCurrentFile(event.target.files[0]);
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
    };
    // const File = event.target.file.files[0];
    // const formData = new FormData();

    // formData.append("logo", File);
    const subEditAdmin = async (event) => {
        event.preventDefault();

        const image = event.target.image.files[0];
        const name = event.target.name.value;
        const phone = event.target.phone.value;

        const formDataAddAdmin = new FormData();
        formDataAddAdmin.append("name", name);
        formDataAddAdmin.append("phone", phone);
        formDataAddAdmin.append("image", image);
        await axios
            .put(
                `${Config.V1_API_URL}admin/auth/update/${row?._id}`,
                formDataAddAdmin,
                {
                    headers: {
                        authorization: `Bearer ${getCookie("adminToken")}`,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    toast({ type: 'success', message: res.data?.message })
                    props.getAdmins()
                    setEditShow(false)
                }
            })
            .catch((error) => {
                toast({ type: 'error', message: error?.response?.data?.message })
            })
            .finally(() => {
                // setEditShow(false)
            })
    }
    const subEditPass = async (event) => {
        event.preventDefault();

        const cpassword = event.target.cpassword.value;
        const npassword = event.target.npassword.value;
        const confirmPassword = event.target.confirmPassword.value;

        if (npassword !== confirmPassword) {
            return toast({ type: 'error', message: "Confirm Password not match!" })
        }
        await axios
            .put(
                `${Config.V1_API_URL}admin/auth/update/password/${row?._id}`,
                { currentPassword: cpassword, newPassword: npassword },
                {
                    headers: {
                        authorization: `Bearer ${getCookie("adminToken")}`,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    toast({ type: 'success', message: res.data?.message })
                    props.getAdmins()
                    setEditShow(false)
                }
            })
            .catch((error) => {
                toast({ type: 'error', message: error?.response?.data?.message })
            })
            .finally(() => {
                // setEditShow(false)
            })
    }
    return (
        <>
            <AppRegistrationIcon sx={{ cursor: (admin.role != 'parentAdmin' && (row?.email != admin?.email)) ? '' : 'pointer', color: (admin.role != 'parentAdmin' && (row?.email != admin?.email)) ? 'gray' : '' }} onClick={() => {
                if ((admin.role != 'parentAdmin' && (row?.email != admin?.email))) {
                    return toast({ type: 'error', message: 'You are not allowed' })
                }
                else {
                    setEditShow(() => true)
                }
            }} />
            <Modal
                show={editShow}
                onHide={() => setEditShow(() => false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Admin Edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '600px', overflow: 'auto' }} className='pe-3'>
                        <form onSubmit={subEditAdmin}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Avatar</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="image"
                                    accept='image/*'
                                    onChange={selectFile}
                                    name='image'
                                />
                            </Form.Group>
                            {previewImage && (
                                <div>
                                    <img
                                        src={previewImage}
                                        alt="logo"
                                        width={100}
                                        className="mb-3"
                                    />{" "}
                                </div>
                            )}
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    defaultValue={row?.name}
                                    type="text"
                                    placeholder="name"
                                    name='name'
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    disabled
                                    value={row?.email}
                                    type="email"
                                    className=''
                                    placeholder="name@example.com"
                                    name='email'
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    defaultValue={row?.phone}
                                    type="text"
                                    placeholder="+880"
                                    name='phone'
                                />
                            </Form.Group>
                            <div className='text-end me-2'>
                                <Button onClick={() => setEditShow(false)} variant='danger' className='me-2'>Cancel</Button>
                                <Button type='submit' variant='success' >Submit</Button>
                            </div>
                        </form>
                        <h5 style={{ color: 'rgb(90, 69, 206)' }}>Password</h5>
                        <form onSubmit={subEditPass}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name='cpassword'
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name='npassword'
                                    required

                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name='confirmPassword'
                                    required
                                />
                            </Form.Group>
                            <div className='text-end me-2'>
                                <Button onClick={() => setEditShow(false)} variant='danger' className='me-2'>Cancel</Button>
                                <Button variant='success' type='submit'>Submit</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setEditShow(false)} variant='danger'>Cancel</Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default AdminEdit;