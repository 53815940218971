import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from 'react-bootstrap/Button';
import { makeRequest, makeRequestWihActualStatus } from '../../../core/services/v1/request';
import Config from '../../../core/config';
import { toast } from '../../../core/lib/toastAlert';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
const AdminBank = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bankDetails, setBankDetails] = useState({
        accountName: "",
        accountNumber: "",
        bankName: "",
        swiftIFSCCode: "",
        type: "",
        accountType: "",
        depositAddress: "",
    })
    const getBankDetails = async () => {
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'admin/get-admin-bank' })
        if (res?.status) {
            const bank = res?.data
            setBankDetails({
                accountName: bank?.accountName,
                accountNumber: bank?.accountNumber,
                bankName: bank?.bankName,
                swiftIFSCCode: bank?.swiftIFSCCode,
                type: bank?.type,
                accountType: bank?.accountType,
                depositAddress: bank?.depositAddress
            })
        }
    }
    useEffect(() => {
        getBankDetails()
    }, [])

    const onSubAdminBank = async (e) => {
        // e.preventDefault();
        setLoading(() => true)
        let data = {
            accountName: bankDetails?.accountName,
            accountNumber: bankDetails?.accountNumber,
            bankName: bankDetails?.bankName,
            swiftIFSCCode: bankDetails?.swiftIFSCCode,
            type: bankDetails?.type,
            accountType: bankDetails?.accountType,
            depositAddress: bankDetails?.depositAddress
        }
        // console.log(data, "data");
        const params = {
            url: `${Config.V1_API_URL}admin/update-admin-bank`,
            method: 'POST',
            body: data
        }

        const response = (await makeRequestWihActualStatus(params));
        if (response.status) {
            toast({ type: 'success', message: response.message })
            setLoading(() => false)
            getBankDetails()
            // e.target.reset()
        }
        else {
            toast({ type: 'error', message: response.message })
            setLoading(() => false)
        }
        setLoading(() => false)
    }
    return (
        <div className='container-fluid mb-3 mx-auto'>

            <div className='mb-3'>
                <h4>Admin Bank</h4>
            </div>
            <div className='d-flex justify-content-center align-items-center w-100'>
                <Card style={{ width: window.innerWidth > 992 ? '75%' : '100%' }}>
                    <CardContent>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Account Name *</Form.Label>
                            <Form.Control size="sm" type="text" required name='accountName' value={bankDetails?.accountName} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, accountName: e.target.value } })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Account Number *</Form.Label>
                            <Form.Control size="sm" type="text" required name='accountNumber' value={bankDetails?.accountNumber} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, accountNumber: e.target.value } })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Bank Name *</Form.Label>
                            <Form.Control size="sm" type="text" required name='bankName' value={bankDetails?.bankName} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, bankName: e.target.value } })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                            <Form.Label>IFSC Code *</Form.Label>
                            <Form.Control size="sm" type="text" required name='swiftIFSCCode' value={bankDetails?.swiftIFSCCode} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, swiftIFSCCode: e.target.value } })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Label>Type *</Form.Label>
                            <Form.Control size="sm" type="text" required name='type' value={bankDetails?.type} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, type: e.target.value } })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                            <Form.Label>Account Type *</Form.Label>
                            <Form.Control size="sm" type="text" required name='accountType' value={bankDetails?.accountType} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, accountType: e.target.value } })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-0" controlId="exampleForm.ControlInput6">
                            <Form.Label>Deposit Address *</Form.Label>
                            <Form.Control size="sm" type="text" required name='accountType' value={bankDetails?.depositAddress} onChange={(e) => {
                                setBankDetails((el) => { return { ...el, depositAddress: e.target.value } })
                            }} />
                        </Form.Group>
                    </CardContent>
                    <CardActions className='d-flex justify-content-end align-items-center mb-2'>
                        <Button size="sm" variant='danger' onClick={() => navigate(-1)}>Cancel</Button>
                        <Button size="sm" variant='primary' onClick={() => onSubAdminBank()} disabled={loading}>{
                            loading ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Loading...</span>
                            </> : <span>Update</span>
                        }</Button>
                    </CardActions>
                </Card>

            </div>
        </div>
    );
};

export default AdminBank;