import { Button } from "@mui/material";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FiSend } from "react-icons/fi";
import { AiOutlineLogin } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import axios from "axios";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/login");
  };

  const sendResetLink = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    // console.log(email)

    await axios
      .post(
        `${Config.V1_API_URL}admin/auth/send-reset-password-link/`,
        { email }
      )
      .then((res) => {
        if (res.status === 200) {
          // alert(res.data.message);
          toast({ type: 'success', message: `${res.data.message}` })
          navigate("/login");
        }
      })
      .catch((err) => {
        // alert(err.response.data.message);
        toast({ type: 'error', message: `${err.response.data.message}` })
      });
  };

  return (
    <div>
      <div className="handleTheLoginBody">
        <div className="container mx-auto">
          <div className=" forCard  w-50 p-5 rounded mx-auto">
            <div className="mx-auto text-center">
              <img
                src="https://i.ibb.co/LQmQWLr/logo192.png"
                className="handleLogoLogin"
                alt="logo"
              />
              <p className="fs-1 Forgot">Forgot Password</p>
              <p className="pb-2">
                Please enter your email address and we will send you a password
                reset link.
              </p>
            </div>
            <hr />
            <div className="mt-4 pt-0">
              <form onSubmit={sendResetLink}>
                <InputGroup className="mb-3 mt-3">
                  <InputGroup.Text className="bg-dark border-0 text-white">
                    <i class="fas fa-envelope"></i>
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Amount (to the nearest dollar)"
                    className="inputBackground custom-white"
                    placeholder="Enter email"
                    type="email"
                    name="email"
                    required
                  />
                </InputGroup>

                <div
                  className="mx-auto text-center mt-4 pt-1"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    style={{ backgroundColor: "#f74545" }}
                    className="button-34 ps-5 pe-5 pt-2 pb-2"
                    type="submit"
                  >
                    <FiSend></FiSend> Send
                  </Button>
                  <Button
                    style={{ backgroundColor: "#f74545" }}
                    onClick={handleGoToLogin}
                    className="button-34 ps-5 pe-5 pt-2 pb-2"
                    type="button"
                  >
                    <AiOutlineLogin /> Sign-in
                  </Button>
                </div>
              </form>
              {/* <div className='mx-auto text-center mt-3'>
                                <Button className='bg-danger border-0 text-center ps-5 pe-5 pt-2 pb-2' type="submit">
                                    Login
                                </Button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
