import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Config from '../../../core/config';
import { makeRequest } from '../../../core/services/v1/request';
import { dateFormat } from '../../../core/helper/date-format';
import PreviewIcon from '@mui/icons-material/Preview';
import { useLocation, useNavigate } from 'react-router-dom';

export default function User() {
    const search = useLocation().search;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getUsers = async () => {
        setLoading(() => true)
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'wallet-user/all' + search })
        if (res?.status) {
            setData(() => res.data)
        }
        setLoading(() => false)
    }
    React.useEffect(() => {
        getUsers();
    }, [])
    return (
        <div className='container-fluid mb-3 mx-auto'>

            <div className='mb-3'>
                <h4>User</h4>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Register On</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>User Name</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Email</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Phone</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Status</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>KYC Status</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Bank Status</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>Loading...</TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                            </TableRow>}
                            {(!loading && (!data || data?.length == 0)) && <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>No Data</TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                            </TableRow>}
                            {(data && data.length > 0) && data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const {
                                        status = 0,
                                        avatar = { id: '', url: '' },
                                        email = '',
                                        walletAddress = '',
                                        referralId = '',
                                        firstName = '',
                                        lastName = '',
                                        dob = '',
                                        gender = '',
                                        phoneNo = '',
                                        address = {},
                                        kyc = {},
                                        bankDetails = {},
                                        myReferralCod = '',
                                        gotReferral = [''],
                                        gotReferralCount = '',
                                        receivedReferralBonus = '',
                                        createdAt = ''
                                    } = row;

                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row?._id}>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{dateFormat(createdAt)}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{firstName}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{email}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{phoneNo}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{status == 1 ? 'Active' : 'De-active'}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{kyc?.status == 0 ? 'Incomplete' : kyc?.status == 1 ? 'Pending' : kyc?.status == 2 ? 'Rejected' : kyc?.status == 3 ? 'Approved' : ''}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{bankDetails?.status == 0 ? 'Incomplete' : bankDetails?.status == 1 ? 'Pending' : bankDetails?.status == 2 ? 'Rejected' : bankDetails?.status == 3 ? 'Approved' : ''}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}><PreviewIcon fontSize='small' style={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/user/${row?._id}`)} /></TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
