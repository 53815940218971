import React from 'react';
import { Container, Row } from 'react-bootstrap';
import HomeCard from './HomeCard';
import './home.css';
import { makeRequest } from '../../../core/services/v1/request';
import Config from '../../../core/config';
import Loader from '../../../component/Loader/Loader';

const Home = () => {
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const getCounts = async () => {
        setLoading(() => true)
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'admin/get-counts' })
        if (res?.status) {
            setData(() => res.data)
        }
        setLoading(() => false)
    }
    React.useEffect(() => {
        localStorage.setItem('page', 'Dashboard')
        getCounts();
    }, [])
    return (
        <div>
            <Container fluid>
                <Row className='g-4'>

                    {
                        loading ? <Loader /> : data.map(el => <HomeCard data={el} loading={loading} />)
                    }
                </Row>
            </Container>
        </div>
    );
};

export default Home;