import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from '../../../core/lib/toastAlert';
import Config from '../../../core/config';
import { getCookie } from '../../../core/helper/cookie';
import { AdminContext } from '../../../core/context/AdminContext/AdminContext';
import { Spinner } from 'react-bootstrap';

const AdminAdd = (props) => {
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    const { admins, setAdmins } = React.useContext(AdminContext);
    const selectFile = (event) => {
        // setCurrentFile(event.target.files[0]);
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
    };
    const {
        setModalShowNewAdmin
    } = props;
    // if (isLoadingAdmin) {
    //     return <Loader></Loader>
    // }
    const subNewAdmin = async (event) => {
        event.preventDefault();
        setLoading(() => true)
        const image = event.target.image.files[0];
        const name = event.target.name.value;
        const phone = event.target.phone.value;
        const email = event.target.email.value;
        const password = event.target.password.value;
        const confirmPassword = event.target.confirmPassword.value;

        const formDataAddAdmin = new FormData();
        formDataAddAdmin.append("name", name);
        formDataAddAdmin.append("email", email);
        formDataAddAdmin.append("phone", phone);
        formDataAddAdmin.append("image", image);
        formDataAddAdmin.append("password", password);

        if (password !== confirmPassword) {
            setLoading(() => false)
            return toast({ type: 'error', message: "Confirm Password not match!" })
        } else {
            await axios
                .post(
                    `${Config.V1_API_URL}admin/auth`,
                    formDataAddAdmin,
                    {
                        headers: {
                            authorization: `Bearer ${getCookie("adminToken")}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 201) {
                        setAdmins((data) => [...data, res.data?.newAdmin])
                        toast({ type: 'success', message: res.data?.message })
                        event.target.reset();
                        setLoading(() => false)
                    }
                })
                .catch((error) => {
                    setLoading(() => false)
                    toast({ type: 'error', message: error?.response?.data?.message })
                })
                .finally(() => {
                    setLoading(() => false)
                    props.onHide()
                })
            setLoading(() => false)
        }
        setLoading(() => false)
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <form onSubmit={subNewAdmin}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            New Admin
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Form> */}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="image"
                                accept='image/*'
                                onChange={selectFile}
                                name='image'
                            />
                        </Form.Group>
                        {previewImage && (
                            <div>
                                <img
                                    src={previewImage}
                                    alt="logo"
                                    width={100}
                                    className="mb-3"
                                />{" "}
                            </div>
                        )}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="name"
                                name='name'
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                name='email'
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="+91"
                                name='phone'
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name='password'
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                name='confirmPassword'
                                required
                            />
                        </Form.Group>
                        {/* </Form> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={props.onHide} variant='danger' disabled={loading}>Cancel</Button>
                        <Button type='submit' variant='success' disabled={loading}>{loading ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ps-1'>Loading...</span>
                            </>
                            : 'Submit'}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};

export default AdminAdd;