import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config";
import { Button } from "react-bootstrap";

const AdminResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (password !== confirmPassword) {
      return toast({ type: 'error', message: 'Password did not match.' })
    }

    axios
      .post(
        `${Config.V1_API_URL}admin/auth/reset-password/`,
        { newPassword: confirmPassword },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast({ type: 'success', message: `${res.data.message}` })
          navigate("/login");
        }
      })
      .catch((err) => {
        toast({ type: 'success', message: `${err.response.data.message}` })
      });

  };

  return (
    <>
      <section className="login-area ptb-50 text-light pt-4">
        <div className="container">
          <div className="login-form">


            <h2 className="text-dark">Password Reset</h2>

            <form onSubmit={(e) => onSubmit(e)}>
              <div className="form-group pt-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  name="password"
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className="form-control my-3"
                  required
                  placeholder="Confirm New Password"
                  name="confirmPassword"

                />
              </div>

              <Button variant="success" type="submit">Reset Password</Button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminResetPassword;
