import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AdminContext } from '../../core/context/AdminContext/AdminContext';
import Loader from '../Loader/Loader';

const ProtectedRoute = ({ children }) => {
    // return children
    const { admin, loading } = useContext(AdminContext);
    let location = useLocation();

    if (loading) {
        return <Loader />
    }

    if (admin && (admin?.role).toLowerCase().includes("admin")) {
        return children
    }
    return <Navigate to="/" state={{ from: location }} />;
};

export default ProtectedRoute;