import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { toast } from '../../../core/lib/toastAlert';
import { deleteCookie, getCookie, setCookie } from "../../helper/cookie";
import Config from "../../config";
export const AdminContext = createContext();

export default function AdminProvider({ children }) {
  const [admin, setAdmin] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [loading, setLoading] = useState(true);
  // console.log(localStorage)
  const adminChecking = async () => {
    setLoading(true);
    await axios
      .get(`${Config.V1_API_URL}admin/auth/admin`, {
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data.admin, "AMI ADMIN");
          setLoading(false);
          setAdmin(res.data.admin);
        }
      })
      .catch((err) => {
        console.log(err, "ADMIN ERROR");
        setAdmin(null);
      })
      .finally(() => {
        setLoading(false);
      })
  };
  const getAdmins = async (token) => {
    await axios
      .get(`${Config.V1_API_URL}admin/auth`, {
        headers: {
          authorization: `Bearer ${token || getCookie("adminToken")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAdmins(() => res.data);
        }
      })
      .catch((err) => {
        console.log(err, "ADMINs ERROR");
        setAdmins(null);
      })
      .finally(() => {
      })
  };
  useEffect(() => {
    getAdmins();
    adminChecking();
  }, []);

  const login = async (email, password) => {
    await axios
      .post(`${Config.V1_API_URL}admin/auth/login`, {
        email,
        password,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsAuthenticating(true);
          setCookie("verify-tokens", res.data.token)
        }
      })
      .catch((error) => {
        console.log(error, "WHILE LOGIN");
        // alert(error.response.data.message);
        toast({ type: 'error', message: `${error.response?.data?.message}` })
      });
  };

  const verifyOtp = async (otp) => {
    await axios
      .post(
        `${Config.V1_API_URL}admin/auth/verify-otp/`,
        {
          otp,
        },
        {
          headers: {
            Authorization: `Bearer ${getCookie("verify-tokens")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsAuthenticating(false);
          setAdmin(res.data.admin);
          setCookie("adminToken", res.data.token)
          setCookie("login", new Date())
          deleteCookie("verify-tokens");
          getAdmins()
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        toast({ type: 'error', message: `${error.response?.data?.message}` })
      });
  };

  const deleteAdmin = async (id) => {
    await axios
      .delete(
        `${Config.V1_API_URL}admin/auth/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getCookie("adminToken")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast({ type: 'success', message: res.data?.message })
          setAdmins((data) => {
            const deleteOne = data.filter(el => el._id !== id)
            return deleteOne
          })
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        toast({ type: 'error', message: `${error.response?.data?.message}` })
      });
  };

  const logout = () => {
    setIsAuthenticating(() => false)
    setAdmin(() => null);
    deleteCookie("verify-tokens");
    deleteCookie("adminToken");
    deleteCookie("login");
  };

  return (
    <AdminContext.Provider
      value={{
        admin,
        isAuthenticating,
        setAdmin,
        logout,
        loading,
        login,
        verifyOtp,
        setIsAuthenticating,
        admins,
        setAdmins,
        deleteAdmin,
        getAdmins
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}
