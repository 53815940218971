import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Config from '../../../core/config';
import { makeRequest } from '../../../core/services/v1/request';
import { dateFormat } from '../../../core/helper/date-format';
import PreviewIcon from '@mui/icons-material/Preview';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Payout() {
    const search = useLocation().search;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getPayouts = async () => {
        setLoading(() => true)
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'admin/get-stake' + search })
        if (res?.status) {
            setData(() => res.data)
        }
        setLoading(() => false)
    }
    React.useEffect(() => {
        getPayouts();
    }, [])
    return (
        <div className='container-fluid mb-3 mx-auto'>

            <div className='mb-3'>
                <h4>Payout</h4>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Request Date</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>User Name</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>User Email</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Plan</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Close Date</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Payout</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Status</TableCell>
                                <TableCell className='fw-bold' style={{ minWidth: window.innerWidth > 600 ? 150 : 120 }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>Loading...</TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                            </TableRow>}
                            {(!loading && (!data || data?.length == 0)) && <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>No Data</TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                                <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}></TableCell>
                            </TableRow>}
                            {(data && data?.length > 0) && data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const {
                                        amount = 0,
                                        price = 0,
                                        balance = 0,
                                        required,
                                        eligibleWithdraw,
                                        withdrawn = 0,
                                        isEligible,
                                        stakeDate = '',
                                        lastInterestDate = '',
                                        status,
                                        withdrawStatus,
                                        withdrawReqDate = '',
                                        name = '',
                                    } = row;
                                    const {
                                        firstName = '',
                                        email = '',
                                    } = row?.userData
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row?._id}>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{dateFormat(withdrawReqDate)}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{firstName}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{email}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{name}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{dateFormat(lastInterestDate)}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{withdrawn}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}>{withdrawStatus == 0 ? 'Pending' : withdrawStatus == 1 ? 'Success' : withdrawStatus == 2 ? 'Rejected' : 'Not Withdrawn'}</TableCell>
                                            <TableCell style={{ minWidth: window.innerWidth > 600 ? 170 : 120 }}><PreviewIcon fontSize='small' style={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/payout/${row?._id}`)} /></TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
