import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { makeRequest, makeRequestWithErrorMessage } from '../../../core/services/v1/request';
import Config from '../../../core/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { dateFormat, formatDate } from '../../../core/helper/date-format';
import { toast } from '../../../core/lib/toastAlert';
import './User.css'

const UserDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null)
    const [status, setStatus] = useState(0)
    const [aadharStatus, setAadharStatus] = useState(0)
    const [panStatus, setPanStatus] = useState(0)
    const [bankStatus, setBankStatus] = useState(0)
    const [loading, setLoading] = useState(true)

    const getUserDetails = async () => {
        setLoading(() => true)
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'wallet-user/user/' + id })
        if (res?.status) {
            setData(() => res.data)
            setStatus(() => res.data?.status)
            setAadharStatus(() => res.data?.kyc?.aadharStatus)
            setPanStatus(() => res.data?.kyc?.panStatus)
            setBankStatus(() => res.data?.bankDetails?.status)
        }
        setLoading(() => false)
    }
    React.useEffect(() => {
        getUserDetails();
    }, [])
    const changeTheStatus = async () => {
        const data = {
            status: status,
            id: id,
        }
        const params = {
            url: `${Config.V1_API_URL}admin/update-user-status`,
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getUserDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }

    const changeTheStatusBank = async () => {
        const data = {
            bankStatus: bankStatus,
            id: id,
        }
        const params = {
            url: `${Config.V1_API_URL}admin/update-user-bankStatus`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getUserDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    const changeTheStatusPan = async () => {
        const data = {
            panStatus: panStatus,
            id: id,
        }
        const params = {
            url: `${Config.V1_API_URL}admin/update-user-panStatus`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getUserDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    const changeTheStatusaadhar = async () => {
        const data = {
            aadharStatus: aadharStatus,
            id: id,
        }
        const params = {
            url: `${Config.V1_API_URL}admin/update-user-aadharStatus`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getUserDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    const resetKyc = async () => {

        const params = {
            url: `${Config.V1_API_URL}admin/reset-user-kyc/${id}`,
            method: 'POST',
            body: {},
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getUserDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    const resetBank = async () => {

        const params = {
            url: `${Config.V1_API_URL}admin/reset-user-bank/${id}`,
            method: 'POST',
            body: {},
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getUserDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    return (
        <div className='container-fluid mb-3 mx-auto overflow-hidden'>

            <div className='mb-3 d-flex justify-content-start align-items-start' style={{ gap: '5px' }}>
                <Avatar alt="Remy Sharp" src={data?.avatar?.url} sx={{ width: 56, height: 56, border: '1px solid rgb(90, 69, 206)' }} /><h4>User Details</h4>
            </div>

            {loading && <p>Loading...</p>}
            <h5 className='fw-bold'>Info</h5>
            <div className='d-flex justify-content-between align-items-start w-100 flex-column flex-xl-row' style={{ gap: '5px' }}>
                <div className='w-100'>
                    <h5 className='fw-bold'>{" "}</h5>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Status:</p>
                        </div>
                        <div>
                            <p className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}>
                                <Form.Select aria-label="Default select example" size="sm" style={{ width: 'fit-content' }} value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option className='d-none'>Select</option>
                                    <option value="0">De-active</option>
                                    <option value="1">Active</option>
                                </Form.Select>
                                <Button variant="primary" size='sm' onClick={() => changeTheStatus()}>Submit</Button>
                            </p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>First Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.firstName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Last Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.lastName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Email:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.email}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Wallet Address:</p>
                        </div>
                        <div>
                            <Form.Control size="sm" className='mb-1' style={{ width: 'fit-content', maxWidth: '100%' }} type="text" placeholder="Small text" value={data?.walletAddress} readOnly />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Phone No:</p>
                        </div>
                        <div>
                            <Form.Control size="sm" className='mb-1' style={{ width: 'fit-content', maxWidth: '100%' }} type="text" placeholder="Small text" value={data?.phoneNo} readOnly />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Referral-Id:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.referralId || 'None'}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Referral-Code:</p>
                        </div>
                        <div>
                            <Form.Control size="sm" className='mb-1' style={{ width: 'fit-content', maxWidth: '100%' }} type="text" placeholder="Small text" value={data?.walletAddress} readOnly />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Total Referred:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.gotReferralCount}</p>
                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <h5 className='fw-bold'>{" "}</h5>
                    <div className='flex-info-two'>
                        <div>
                            <p>DOB:</p>
                        </div>
                        <div>
                            <p className='text-break'>{dateFormat(data?.dob)}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Gender:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.gender}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Received Bonus:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.receivedReferralBonus ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Address-No:</p>
                        </div>
                        <div>
                            <Form.Control size="sm" className='mb-1' style={{ width: 'fit-content', maxWidth: '100%' }} type="text" placeholder="Small text" value={data?.address?.addressNo} readOnly />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>City:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.address?.city}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>State:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.address?.state}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Zip:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.address?.zip}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Registered:</p>
                        </div>
                        <div>
                            <p className='text-break'>{dateFormat(data?.createdAt)}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Referred Address:</p>
                        </div>
                        <div>
                            <p className='text-break'>{(data?.gotReferral)?.map(el => <><Form.Control size="sm" className='mb-1' style={{ width: 'fit-content', maxWidth: '100%' }} type="text" placeholder="Small text" value={el} readOnly /></>)}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='mb-3 d-flex justify-content-between align-items-start' style={{ gap: '5px' }}>
                <h5 className='fw-bold'>KYC</h5>
                <Button variant='danger' size='sm'>Reset</Button>
            </div> */}
            <div className='d-flex justify-content-between align-items-start w-100 flex-column flex-xl-row mt-3' style={{ gap: '5px' }}>
                <div className='w-100'>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <h5 className='fw-bold'>KYC</h5>
                        </div>
                        <div>
                            <Button variant='danger' size='sm' onClick={() => resetKyc()}>Reset KYC</Button>
                        </div>
                    </div>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Pan Card Number:</p>
                        </div>
                        <div>
                            <p>{data?.kyc?.panNumber}</p>
                        </div>
                    </div>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Pan Card Image:</p>
                        </div>
                        <div>
                            <img src={data?.kyc?.panCard?.url} alt='pan' width={100} style={{ border: '1px solid rgb(90, 69, 206)', borderRadius: '5px' }} />
                        </div>
                    </div>

                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Pan Action:</p>
                        </div>
                        <div>
                            {(data?.kyc?.panStatus != 0 && data?.kyc?.panStatus != 1) ? <p>
                                {data?.kyc?.panStatus == 2 ? 'Rejected' : data?.kyc?.panStatus == 3 ? 'Approved' : ''}
                            </p> : data?.kyc?.panStatus == 0 ? <p>
                                {data?.kyc?.panStatus == 0 ? 'Incomplete' : ''}
                            </p> :
                                <p className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}>
                                    <Form.Select aria-label="Default select example" size="sm" style={{ width: 'fit-content' }} value={panStatus} onChange={(e) => setPanStatus(e.target.value)}>
                                        <option className='d-none'>Select</option>
                                        <option value="1" disabled>Pending</option>
                                        <option value="2">Rejected</option>
                                        <option value="3">Approved</option>
                                    </Form.Select>
                                    <Button variant="primary" size='sm' onClick={() => changeTheStatusPan()}>Submit</Button>
                                </p>
                            }
                        </div>
                    </div>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Aadhar Card Number:</p>
                        </div>
                        <div>
                            <p>{data?.kyc?.aadharNumber}</p>
                        </div>
                    </div>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Aadhar Card Image:</p>
                        </div>
                        <div>
                            <img src={data?.kyc?.aadharCard?.url} alt='aadhar' width={100} style={{ border: '1px solid rgb(90, 69, 206)', borderRadius: '5px' }} />
                        </div>
                    </div>

                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Aadhar Action:</p>
                        </div>
                        <div>
                            {(data?.kyc?.aadharStatus != 0 && data?.kyc?.aadharStatus != 1) ? <p>
                                {data?.kyc?.aadharStatus == 2 ? 'Rejected' : data?.kyc?.aadharStatus == 3 ? 'Approved' : ''}
                            </p> : data?.kyc?.aadharStatus == 0 ? <p>
                                {data?.kyc?.aadharStatus == 0 ? 'Incomplete' : ''}
                            </p> :
                                <p className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}>
                                    <Form.Select aria-label="Default select example" size="sm" style={{ width: 'fit-content' }} value={aadharStatus} onChange={(e) => setAadharStatus(e.target.value)}>
                                        <option value="1" disabled>Pending</option>
                                        <option value="2">Rejected</option>
                                        <option value="3">Approved</option>
                                    </Form.Select>
                                    <Button variant="primary" size='sm' onClick={() => changeTheStatusaadhar()}>Submit</Button>
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <h5 className='fw-bold'>Bank</h5>
                        </div>
                        <div className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}>
                            {(data?.bankDetails?.status != 0 && data?.bankDetails?.status != 1) ? <p>
                                {data?.bankDetails?.status == 2 ? 'Rejected' : data?.bankDetails?.status == 3 ? 'Approved' : ''}
                            </p> : data?.bankDetails?.status == 0 ? <p>
                                {data?.bankDetails?.status == 0 ? 'Incomplete' : ''}
                            </p> :
                                <>
                                    <Form.Select aria-label="Default select example" size="sm" style={{ width: 'fit-content' }} value={bankStatus} onChange={(e) => setBankStatus(e.target.value)}>
                                        <option className='d-none'>Select</option>
                                        <option value="1" disabled>Pending</option>
                                        <option value="2">Rejected</option>
                                        <option value="3">Approved</option>
                                    </Form.Select>
                                    <Button variant="primary" size='sm' onClick={() => changeTheStatusBank()}>Submit</Button>
                                </>}   <Button variant='danger' size='sm' onClick={() => resetBank()}>Reset Bank</Button>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.bankDetails?.accountName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Number:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.bankDetails?.accountNumber}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>IFSC Code:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.bankDetails?.ifscCode}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Bank Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.bankDetails?.bankName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;