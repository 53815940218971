import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Button } from '@mui/material';
import AdminEdit from './AdminEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AdminAdd from './AdminAdd';
import './admin.css'
import { AdminContext } from '../../../core/context/AdminContext/AdminContext';
import { toast } from '../../../core/lib/toastAlert';

const columns = [
    { id: 'img', label: 'Avatar', align: 'left' },
    { id: 'code', label: 'Email', align: 'center' },
    { id: 'name', label: 'Name', align: 'center' },
    {
        id: 'population',
        label: 'Phone',
        align: 'center'
    },
    {
        id: 'size',
        label: 'Actions',
        align: 'center'
    },
];

function createData(name, code, population, size) {
    return { name, code, population, size };
}


export default function Admin() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [newShow, setNewShow] = React.useState(false);
    const { admins, deleteAdmin, admin, getAdmins } = React.useContext(AdminContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    React.useEffect(() => {
        if (!admins || admins.length == 0) {
            getAdmins()
        }
        // window.scrollTo({
        //     behavior: 'smooth',
        //     top: 0,
        //     left: 0
        // })
    }, [admins])
    return (
        <div>
            <div className='text-end mb-3'>
                <Button variant="outlined" startIcon={<AddCircleIcon />} sx={{ border: '1px solid rgb(90, 69, 206)', color: 'rgb(90, 69, 206)' }} onClick={() => setNewShow(true)} disabled={admin.role != 'parentAdmin'}>
                    Add Admin
                </Button>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 900, overflow: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column?.id}
                                        align={column?.align}
                                        sx={{ fontWeight: 700 }}
                                    >
                                        {column?.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {admins && admins
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                            <TableCell align='left' sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '4px' }}>
                                                <Avatar alt="logo" src={row?.avatar?.url} sx={{ width: 30, height: 30 }} />
                                                {row?.role == 'parentAdmin' && <WorkspacePremiumIcon fontSize='small' sx={{ color: 'rgb(90, 69, 206)' }} />}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row?.email || '--'}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row?.name || '--'}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {row?.phone || '--'}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <AdminEdit row={row} admin={admin} getAdmins={getAdmins} />
                                                <DeleteIcon sx={{ cursor: (admin.role != 'parentAdmin' && (row?.email != admin?.email)) ? '' : 'pointer', color: (admin.role != 'parentAdmin' && (row?.email != admin?.email)) ? 'gray' : '' }} onClick={() => {
                                                    if ((admin.role != 'parentAdmin' && (row?.email != admin?.email))) {
                                                        return toast({ type: 'error', message: 'You are not allowed' })
                                                    }
                                                    else {
                                                        deleteAdmin(row?._id)
                                                    }
                                                }} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={admins?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {newShow && <AdminAdd show={newShow} onHide={() => setNewShow(false)} />}
        </div >
    );
}