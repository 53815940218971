import React from 'react';
import { RingLoader } from 'react-spinners';

const Loader = () => {
    return (
        <div className='d-flex justify-content-center align-items-start pt-4'>
            <RingLoader color="rgb(90, 69, 206)" />
        </div>
    );
};

export default Loader;