import { Route, Routes, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import './App.css';
import Home from './pages/dashboard/Home/Home';
import ProfileProtected from './component/ProtectedRoute/ProtectedRoute';
import ScrollToTop from './component/ScrollToTop/ScrollToTop';
import Dashboard from './pages/dashboard/Dashboard/Dashboard';
import Payout from './pages/dashboard/Payout/Payout';
import PayoutDetails from './pages/dashboard/Payout/PayoutDetails';
import User from './pages/dashboard/User/User';
import UserDetails from './pages/dashboard/User/UserDetails';
import { useEffect } from 'react';
import AdminBank from './pages/dashboard/AdminBank/AdminBank';
import Transaction from './pages/dashboard/Transaction/Transaction';
import TransactionDetails from './pages/dashboard/Transaction/TransactionDetails';
import InstaTrade from './pages/dashboard/InstaTrade/InstaTrade';
import InstaTradeDetails from './pages/dashboard/InstaTrade/InstaTradeDetails';
import Login from './pages/authentication/Login/Login';
import Otp from './pages/authentication/Login/Otp';
import ForgetPassword from './pages/authentication/Login/ForgetPassword';
import AdminResetPassword from './pages/authentication/Login/AdminResetPassword';
import Admin from './pages/dashboard/Admin/Admin';
import Stake from './pages/dashboard/Stake/Stake';
import StakeDetails from './pages/dashboard/Stake/StakeDetails';

function App() {
  // const navigate = useNavigate()
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);
  const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });
  const dashboardRoutes = [
    { path: "home", component: <Home />, id: 8 },
    { path: "home", component: <Home />, id: 9 },
    { path: "payout", component: <Payout />, id: 10 },
    { path: "payout/:id", component: <PayoutDetails />, id: 11 },
    { path: "user", component: <User />, id: 12 },
    { path: "user/:id", component: <UserDetails />, id: 13 },
    { path: "bank", component: <AdminBank />, id: 14 },
    { path: "transaction", component: <Transaction />, id: 15 },
    { path: "transaction/:id", component: <TransactionDetails />, id: 16 },
    { path: "instaTrade", component: <InstaTrade />, id: 17 },
    { path: "instaTrade/:id", component: <InstaTradeDetails />, id: 18 },
    { path: "admin", component: <Admin />, id: 19 },
    { path: "stake", component: <Stake />, id: 20 },
    { path: "stake/:id", component: <StakeDetails />, id: 21 },
  ]

  const routes = [
    { path: "/", component: <Login />, id: 1 },
    { path: "/login", component: <Login />, id: 2 },
    { path: "/otp", component: <Otp expiryTimestamp={time} />, id: 3 },
    { path: "/forget-password", component: <ForgetPassword />, id: 4 },
    { path: "/reset-password/:token", component: <AdminResetPassword />, id: 5 },
    { path: "/dashboard/*", component: <ProfileProtected><Dashboard /></ProfileProtected>, children: dashboardRoutes, id: 6 },
  ]

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          {
            routes.map(route => {
              return (
                <Route path={route?.path} element={route?.component} key={route?.id} onUpdate={() => window.scrollTo(0, 0)}>
                  {route?.children && (
                    route?.children.map((child, index) => {
                      return index === 0 ?
                        (<Route key={child?.id} index element={child?.component} />) :
                        (<Route key={child?.id} path={child?.path} element={child?.component} />)
                    })
                  )}
                </Route>
              )
            })
          }
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
