import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest, makeRequestWithErrorMessage } from '../../../core/services/v1/request';
import Config from '../../../core/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { dateFormat, formatDate } from '../../../core/helper/date-format';
import { toast } from '../../../core/lib/toastAlert';
import '../User/User.css';

const TransactionDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)
    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(true)

    const getTransactionDetails = async () => {
        setLoading(() => true)
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'admin/get-trans/' + id })
        if (res?.status) {
            setData(() => res.data[0])
            setUser(() => res.data[0]?.userDetails)
            setStatus(() => res.data[0]?.status)
        }
        setLoading(() => false)
    }
    React.useEffect(() => {
        getTransactionDetails();
    }, [])
    const changeTheStatus = async () => {
        const data = {
            status: status,
            id: id,
        }
        const params = {
            url: `${Config.V1_API_URL}admin/update-trans`,
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            getTransactionDetails();
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    return (
        <div className='container-fluid mb-3 mx-auto overflow-hidden'>

            <div className='mb-3'>
                <h4>Transaction Details</h4>
            </div>

            {loading && <p>Loading...</p>}
            <div className='d-flex justify-content-between align-items-start w-100 flex-column flex-xl-row' style={{ gap: '5px' }}>
                <div className='w-100'>
                    <h5 className='fw-bold'>Transaction Info:</h5>

                    <div className='flex-info-two'>
                        <div>
                            <p>Currency:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.currency}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Amount:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.amount}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Type:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.type}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>TxnId:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.txnId}</p>
                        </div>
                    </div>
                    <div className='flex-info-two mb-2'>
                        <div>
                            <p>Attachment:</p>
                        </div>
                        <div>
                            <img src={data?.attachment?.url} alt='img' width={100} style={{ border: '1px solid rgb(90, 69, 206)', borderRadius: '5px' }} />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Receive Amount:</p>
                        </div>
                        <div>
                            <p className='text-break'>{data?.receiveAmount}</p>
                        </div>
                    </div>

                    <div className='flex-info-two'>
                        <div>
                            <p style={{ textTransform: 'capitalize' }}>{data?.type} Req Date:</p>
                        </div>
                        <div>
                            <p className='text-break'>{dateFormat(data?.createdAt)}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>status:</p>
                        </div>
                        <div>
                            {data?.status != 0 ? <p>{data?.status == 1 ? 'Completed' : data?.status == 2 ? 'Cancelled' : ''}</p> : <p className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}><Form.Select aria-label="Default select example" size="sm" style={{ width: 'fit-content' }} value={status} onChange={(e) => setStatus(e.target.value)}><option className='d-none'>Select</option><option value="0" disabled>Pending</option><option value="1">Completed</option><option value="2">Cancelled</option></Form.Select><Button variant="primary" size='sm' onClick={() => changeTheStatus()}>Submit</Button></p>}

                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <h5 className='fw-bold'>User Info:</h5>

                    <div className='flex-info-two'>
                        <div>
                            <p>First Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.firstName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Last Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.lastName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Email:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.email}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Wallet Address:</p>
                        </div>
                        <div>
                            <Form.Control size="sm" className='mb-1' type="text" placeholder="Small text" value={user?.walletAddress} readOnly />
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Phone No:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.phoneNo}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.accountName}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Account Number:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.accountNumber}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>IFSC Code:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.ifscCode}</p>
                        </div>
                    </div>
                    <div className='flex-info-two'>
                        <div>
                            <p>Bank Name:</p>
                        </div>
                        <div>
                            <p className='text-break'>{user?.bankDetails?.bankName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;